/*
ON LOAD PAGE FUNCTION
*/

let windowWidth = window.innerWidth,
    windowHeight = window.innerHeight;

jQuery( window ).bind( 'resize', function() {

  windowWidth = window.innerWidth;
  windowHeight = window.innerHeight;

} );

jQuery( window ).on( 'load', function() {

  postGallery();
  initFancybox();

} );

/*
INITIALIZATION FUNCTIONS
*/

jQuery( document ).ready( function() {

  initPlugins()
  panelSearch();
  desktopNav();
  mobileNav();
  linkToTopClick();
  scrollDown();
  readMore();
  commentsView();

} );

/*
ON SCROLL PAGE FUNCTIONS
*/

jQuery( window ).on( 'scroll', function() {

  linkToTopScroll( $(this) );

} );

function initPlugins() {

  formValidation( {
    classForms:  'js-form-add-comment',
    errorEmpty:  'Это поле обязательно для заполнения!',
    errorLength: 'Введите более 1-го символа!',
    errorEmail:  'Некорректный e-mail адрес!',
    ajax:        false
  } );

  initSliders();
  initFancybox();

}

function initFancybox() {
  let fancybox = $('.fancybox');

  if ( fancybox.length ) {

    fancybox.fancybox( {
      margin: 30,
      padding: 0,
      prevEffect: 'fade',
      nextEffect: 'fade'
    } );

  }

}

function initSliders() {
  let mainSlider = $('.js-main-slider'),
    gallerySlider = $('.js-gallery-slider');

  if ( mainSlider.length ) {
    mainSlider.slick( {
      fade: true
    } );
  }

  if ( gallerySlider.length ) {
    gallerySlider.slick( {
      slidesToShow: 5,
      centerMode: true,
      responsive: [
        {
          breakpoint: 1441,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            arrows: false
          }
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
            arrows: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            arrows: false
          }
        }
      ]
    } );
  }

}

function panelSearch() {
  let toggleSearchButton = $('.js-toggle-search'),
      boxSearch = $('.js-box-search'),
      panelSearch = $('.js-search-panel'),
      formSearch = $('.js-search-form'),
      inputSearch = $('.js-search-input'),
      submitSearchButton = $('.js-search-submit');

  if ( formSearch.length ) {

    toggleSearchButton.on( 'click', function ( e ) {
      e.preventDefault();

      $(this).toggleClass('is-active');
      panelSearch.toggleClass('is-active');

      if ( !panelSearch.hasClass('is-active') ) {

        inputSearch.val('');
        submitSearchButton.addClass('is-disabled');

      } else {

        setTimeout( function() {

          inputSearch.focus();

        }, 300)

      }

    } );

    formSearch.on( 'submit', function( e ) {

      if ( $(this).find(submitSearchButton).hasClass('is-disabled') ) e.preventDefault();

    } );

    inputSearch.on( 'keyup', function() {
      let thisSubmit = $(this).closest(formSearch).find(submitSearchButton);

      $(this).val().length === 0 ? thisSubmit.addClass('is-disabled') : thisSubmit.removeClass('is-disabled');

    } );

    $( document ).on( 'click', function ( e ) {

      if ( !boxSearch.is(e.target) && boxSearch.has(e.target).length === 0 ) {

        toggleSearchButton.removeClass('is-active');
        panelSearch.removeClass('is-active');
        inputSearch.val('');
        submitSearchButton.addClass('is-disabled');

      }

    } );

  }

}

function desktopNav() {
  let navList = $('.nav-list'),
      navListDropdown = navList.find('.sub-menu');

  if ( navListDropdown.length ) {

    navListDropdown.each( function() {

      $(this).closest('li').addClass('has-dropdown');

    } );

  }
}

function mobileNav() {
  let mobileNav = $('.js-mobile-nav'),
      mobileNavToggle = $('.js-toggle-mobile-nav'),
      formSearch = $('.js-mobile-search-form'),
      inputSearch = $('.js-mobile-search-input'),
      submitSearchButton = $('.js-mobile-search-submit'),
      dropdownBox = mobileNav.find('.sub-menu'),
      dropdownToggle = '';

  if ( mobileNav.length ) {

    if ( dropdownBox.length ) {

      dropdownBox.each( function() {

        $(this).closest('li').addClass('js-toggle-dropdown has-dropdown');

      } );

    }

    dropdownToggle = $('.js-toggle-dropdown > a');

    mobileNavToggle.on( 'click', function( e ) {
      e.preventDefault();

      $(this).toggleClass('is-active');
      mobileNav.toggleClass('is-active');
      $('body').toggleClass('open-mobile-nav');
      $('html').toggleClass('open-mobile-nav');
      dropdownToggle.parent().removeClass('is-active');
      dropdownBox.removeClass('is-active');
      inputSearch.val('');
      submitSearchButton.addClass('is-disabled');

    } );

    $( document ).on( 'click', function ( e ) {

      if ( !mobileNavToggle.is(e.target) && !mobileNavToggle.has(e.target).length && !mobileNav.is(e.target) && !mobileNav.has(e.target).length ) {

        mobileNavToggle.removeClass('is-active');
        mobileNav.removeClass('is-active');
        dropdownToggle.parent().removeClass('is-active');
        dropdownBox.removeClass('is-active');
        $('html').removeClass('open-mobile-nav');
        $('body').removeClass('open-mobile-nav');

      }

    } );

    if ( dropdownBox.length || dropdownToggle.length ) {

      dropdownToggle.on( 'click', function( e ) {
        e.preventDefault();

        $(this).closest('li').toggleClass('is-active');
        $(this).closest('li').find('.sub-menu').toggleClass('is-active');

      } );

    }

    formSearch.on( 'submit', function( e ) {

      if ( $(this).find(submitSearchButton).hasClass('is-disabled') ) e.preventDefault();

    } );

    inputSearch.on( 'keyup', function() {
      let thisSubmit = $(this).closest(formSearch).find(submitSearchButton);

      !$(this).val().length ? thisSubmit.addClass('is-disabled') : thisSubmit.removeClass('is-disabled');

    } );

  }
}

function linkToTopClick() {
  let linkToTop = $('.js-link-to-top');

  if ( linkToTop.length ) {

    linkToTop.on( 'click', function( e ) {
      e.preventDefault();

      $('html, body').animate( {

        scrollTop: 0

      }, 500 );

    } );

  }
}

function linkToTopScroll( body ) {
  let linkToTop = $('.js-link-to-top'),
      footer = $('.view-footer'),
      footerLoadPositionBottom = 30,
      footerHeight = footer.outerHeight() + footerLoadPositionBottom,
      footerOffsetTop = $(document).height() - body.height() - footerHeight + footerLoadPositionBottom;

  if ( linkToTop.length ) {

    if ( body.scrollTop() > 200 ) {

      linkToTop.removeClass('is-hide');

    } else {

      linkToTop.addClass('is-hide');

    }

    if ( body.scrollTop() > footerOffsetTop ) {

      linkToTop.addClass('position-bottom');

    } else if ( body.scrollTop() < footerOffsetTop + footerLoadPositionBottom ) {

      linkToTop.removeClass('position-bottom');

    }

    if ( linkToTop.hasClass('position-bottom') ) {

      linkToTop.css('bottom', footerHeight + 'px');

    } else {

      linkToTop.css('bottom', footerLoadPositionBottom + 'px');

    }

  }

}

function scrollDown() {
  let scrollDownBtn = $('.js-scroll-down');

  if ( scrollDownBtn.length ) {

    scrollDownBtn.on( 'click', function( e ) {
      e.preventDefault();

      $('html, body').animate( {

        scrollTop: windowHeight

      }, 1500 );

    } );

  }

}

function readMore() {
  let commentBox = $('.comment-body'),
      commentText = $('.comment-content');

  if ( commentBox.length && commentText.length ) {

    commentText.each( function() {
      let thisComment = $(this),
          fullText = thisComment.text(),
          cutLength = 250,
          visibleText = fullText.substr(0, cutLength),
          hideText = fullText.substr(cutLength),
          linkTextDefault = 'Развернуть',
          linkTextToggle = 'Свернуть',
          readMoreBtn = `<a href="javascript:void(0);" class="js-read-more read-more-link">${linkTextDefault}</a>`;

      if ( fullText.length > cutLength ) {

        $(this).html(`${visibleText}<span class="clamp">...</span><span class="hide-text">${hideText}</span> ${readMoreBtn}`);

      }

      let readMoreBtnElement = thisComment.find('.js-read-more');

      readMoreBtnElement.on( 'click', function( e ) {
        e.preventDefault();

        thisComment.hasClass('is-expand') ? $(this).text(linkTextDefault) : $(this).text(linkTextToggle);
        thisComment.toggleClass('is-expand');

      } );

    } );

  }

}

function commentsView() {
  let commentBox = $('.comment.depth-1 > .children'),
      commentBoxParent = $('.comment.depth-1');

  if ( commentBox.length ) {

    commentBox.each( function() {

      $('<span class="toggle-replies">Показать ответы</span>').insertBefore( $(this) );

      let toggleBtn = $(this).closest(commentBoxParent).find('.toggle-replies');

      if ( toggleBtn.length ) {

        toggleBtn.on( 'click', function() {
          let toggleText = 'Показать ответы';

          $(this).toggleClass('is-active');
          $(this).closest(commentBoxParent).find('> .children').toggleClass('is-active');

          if ( $(this).hasClass('is-active') ) {

            toggleText = 'Скрыть';

          }

          $(this).text( toggleText );

        } );

      }

    } );

  }

}

function postGallery() {
  let pagePost = $('.page-post'),
      gallery = $('.gallery');

  if ( pagePost.find(gallery).length ) {
    let galleryLink = pagePost.find(gallery).find('.gallery-item a');

    galleryLink.each( function() {

      $(this).addClass('fancybox');
      $(this).attr('data-fancybox-group', 'gallery');


    } );

    galleryLink.on( 'click', function( e ) {
      e.preventDefault();
    } );

  }

}